<template>
  <a-card :bordered="false" class="card-area">
    百科评论
    <common-table
      ref="table"
      path="newsComment"
      :columns="columns">

      <template slot="operation" slot-scope="{record}">
        <action-delete  @click="remove(record)"></action-delete>
      </template>

    </common-table>

  </a-card>
</template>
<script>

export default {

  data () {
    return {

    }
  },
  created () {
    this.getList()
  },
  computed: {
    columns () {
      return [
        {
          title: '评论内容',
          dataIndex: 'content'
        },
        {
          title: '评论时间',
          dataIndex: 'createdAt'
        },
        {
          title: '用户昵称',
          dataIndex: 'nickName'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width:80,
          scopedSlots: { customRender: 'operation' }
        }
      ]
    }
  },
  methods: {

    remove (record) {
      var that = this
      this.$confirm({
        title: '是否删除评论?',
        content: '该操作不可撤销',
        centered: true,
        onOk () {
          that.$delete('newsComment/' + record.id).then(() => {
            that.$message.success('删除成功')
            that.getList()
          })
        }
      })
    },

    getList () {
      this.$refs.table.getData()
    }

  }
}
</script>
<style lang="less" scoped>

</style>
